$primaryColor: #f4762a;
$secondaryColor: rgb(181, 180, 180);
;
$greenColor: rgb(108, 172, 25);


h1 {
    color: $primaryColor;
}

.Header1-text {
    color: $primaryColor;
    font-size: x-large;
    margin-top: 20px;
    margin-bottom: 5px;
}

.Header2-text {
    color: $primaryColor;
    font-size: large;
    margin-top: 20px;
    margin-bottom: 10px;
}

.search-tab-custom {
    ::before {
        border-bottom: hidden !important;
    }
}

.search-box-container {
    .visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 2s linear;
    }

    .hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 2s, opacity 2s linear;
    }
}

.mobile-search-modal {

    .ant-modal,
    .ant-modal-content {
        height: 100vh;
        width: 100vw;
        margin: 0;
        top: 0;
    }

    .ant-modal-body {
        height: calc(100vh - 110px);
    }
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

.highlight-text {
    color: $primaryColor;
}

.renderCom-default-no-image {
    padding: 50px 50px !important;
}

.social-icon-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.svg-social-icon {
    width: 30px;
    height: 30px;  
    margin-right: 5px;
}

@media only screen and (max-width: 895px) {
    .container {
        &.copyright {
            padding-bottom: 50px !important;
            // background-color: red;
        }
    }
}